import { Controller } from "stimulus"
import { get } from "@rails/request.js"
import "form-request-submit-polyfill"


export default class ShapeCategoriesController extends Controller {
  
  static targets = ["shapeCategory", "shapes", "submitButton"]
  static values = { source: String }

  
  initialize() {
    let shapeCategory = this.shapeCategoryTarget.selectedOptions[0].value
    let shapeValue = this.shapesTarget.value
    this.get_data(shapeCategory, shapeValue)
    console.log(this.sourceValue)
  }   
   
  changeCategory(event) {
    let shapeCategory = event.target.selectedOptions[0].value
    this.get_data(shapeCategory)
  }
    
  async get_data(shapeCategory, shapeValue) {
    if (this.sourceValue === "new-order") {
      var response = await get(`/shapes/shape_category?shapeCategory=${shapeCategory}&source=newOrder`, {
        responseKind: "json"
      })
      var shapes = await response.json
    } else if (this.sourceValue === "retail-products-new") {
      var response = await get(`/shapes/shape_category?shapeCategory=${shapeCategory}&source=retailProductsNew`, {
        responseKind: "json"
      })
      var shapes = await response.json
    }

    this.manageSelect(shapes, shapeValue)
  }


  manageSelect(shapes, shapeValue){
    let numberOfItems = shapes.length
    this.shapesTarget.innerHTML = "";
    if (numberOfItems == 0) {
      this.noGroups()
    } else {
      this.updateSelect(shapes, shapeValue)
    }
  }


  updateSelect(shapes, shapeValue) {
    shapes.sort((a,b) => a.name > b.name)
    shapes.forEach((group) => {
      const option = document.createElement("option");
      option.value = group.id;
      option.innerHTML = group.name;
      this.shapesTarget.appendChild(option);
    });
    if (typeof shapeValue !== "undefined") {
    this.shapesTarget.value = shapeValue
    }
    // this.shapesTarget.value =
    this.submitButtonTarget.disabled = false;
  }


  noGroups() {
    const option = document.createElement("option");
    option.innerHTML = "Nincs forma";
    this.shapesTarget.appendChild(option);
    this.submitButtonTarget.disabled = true;
  }
  
  
}


